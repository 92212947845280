<template>
  <v-fade-transition mode="out-in">
    <div
      v-if="testStarted && test && test.questions?.[currentQuestionId]"
      :key="currentQuestionId"
      ref="testCard"
      class="container"
    >
      <div class="page-number">
        <div class="current-page">
          {{ currentQuestionId + 1 }}
        </div>
        <div class="total-pages">
          {{ $store.getters.langData.test.overall }} {{ test.questions.length }}
          {{
            test.questions.length === 1
              ? $store.getters.langData.test.singleQuestion
              : $store.getters.langData.test.multipleQuestion
          }}
        </div>
      </div>
      <v-img
        v-if="test.questions[currentQuestionId].banner_url"
        :src="test.questions[currentQuestionId].banner_url"
        width="300px"
        height="200px"
        cover
      />
      <p class="question">
        {{ test.questions[currentQuestionId].text }}
      </p>
      <div class="answers">
        <v-container v-if="test.questions[currentQuestionId].multi" fluid>
          <v-checkbox
            v-for="answer in test.questions[currentQuestionId].answers"
            :key="answer.id"
            v-model="multiAnswers"
            :label="answer.text"
            :value="answer.id"
            color="primary"
            class="mt-2"
          ></v-checkbox>
        </v-container>
        <v-radio-group v-model="currentAnswerId" v-else row>
          <v-radio
            v-for="answer in test.questions[currentQuestionId].answers"
            :key="answer.id"
            :label="answer.text"
            :value="answer.id"
            color="primary"
            class="mt-2"
          />
        </v-radio-group>
      </div>
      <div class="btn-actions">
        <v-btn
          color="transparent"
          variant="flat"
          class="text-capitalize btn-disabled-opacity"
          :disabled="currentQuestionId === 0"
          @click="previousQuestion"
        >
          <v-icon icon="mdi-arrow-left" class="mr-3" />
          {{ $store.getters.langData.test.previousQuestion }}
        </v-btn>
        <v-btn
          v-if="currentQuestionId < test.questions.length - 1"
          color="primary"
          variant="flat"
          class="text-capitalize btn-disabled-opacity"
          :disabled="multiAnswers.length === 0 && currentAnswerId === null"
          @click="nextQuestion"
        >
          {{ $store.getters.langData.test.nextQuestion }}
        </v-btn>
        <v-btn
          v-else
          color="primary"
          variant="flat"
          class="text-capitalize btn-disabled-opacity"
          :disabled="multiAnswers.length === 0 && currentAnswerId === null"
        >
          {{ $store.getters.langData.test.finishTest }}
          <v-dialog v-model="testSubmitDialog" activator="parent" width="auto">
            <v-card>
              <v-card-text>
                {{ $store.getters.langData.test.confirmationText }}
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  variant="text"
                  class="text-capitalize"
                  @click="testSubmitDialog = false"
                  >{{ $store.getters.langData.test.cancel }}</v-btn
                >
                <v-btn
                  color="primary"
                  variant="flat"
                  class="text-capitalize"
                  :loading="submittingTest"
                  @click="submitTest"
                  >{{ $store.getters.langData.test.submit }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-btn>
      </div>
    </div>
  </v-fade-transition>
  <div
    v-if="startingTest"
    class="spinner-container d-flex justify-center align-center"
  >
    <v-progress-circular
      indeterminate
      size="64"
      color="primary"
    ></v-progress-circular>
  </div>
</template>

<script>
import config from "@/helpers/api";
export default {
  name: "QuizView",
  data() {
    return {
      course: {
        name: null,
        totalQuestions: null,
      },
      test: null,
      testStarted: false,
      currentQuestionId: null,
      currentAnswerId: null,
      testSubmitDialog: false,
      submittingTest: false,
      startingTest: false,
      answerArray: [],
      multiAnswers: [],
    };
  },
  computed: {
    courseTotalQuestionsText() {
      return `kopā ${this.course.totalQuestions} jautājum${
        this.course.totalQuestions !== 1 &&
        (this.course.totalQuestions % 10 !== 1 ||
          this.course.totalQuestions % 100 === 11)
          ? "i"
          : "s"
      }`;
    },
  },
  watch: {
    currentQuestionId(data) {
      this.currentAnswerId = this.answerArray[data] || null;
    },
  },
  async mounted() {
    this.startingTest = true;
    this.testStarted = true;
    return fetch(`${config.baseURL}/current_test`, {
      method: "GET",
      headers: config.headers,
    })
      .then((response) => {
        if (!response.ok) {
          this.$router.push({
            name: "home",
          });
        }
        return response.json();
      })
      .then((response) => {
        this.test = response.data;
        this.currentQuestionId = 0;
      })
      .finally(() => {
        this.startingTest = false;
      });
  },
  methods: {
    previousQuestion() {
      if (this.currentAnswerId != null) this.setQuestionAnswer();
      this.currentAnswerId = null;

      if (this.currentQuestionId > 0) {
        this.currentQuestionId--;
      }
    },
    async nextQuestion() {
      this.setQuestionAnswer();
      this.currentAnswerId = null;

      if (this.currentQuestionId < this.test.questions.length - 1) {
        this.currentQuestionId++;
      }
    },
    setQuestionAnswer() {
      if (this.test.questions[this.currentQuestionId].multi) {
        this.answerArray = [...this.answerArray, this.multiAnswers];
        this.multiAnswers = [];
      } else {
        this.answerArray[this.currentQuestionId] = this.currentAnswerId;
      }
    },
    async submitTest() {
      this.setQuestionAnswer();
      this.currentAnswerId = null;
      this.submittingTest = true;
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("login");
      return fetch(`${config.baseURL}/statistics/increment/filling_times`, {
        method: "POST",
        headers: config.headers,
        body: JSON.stringify({
          "g-recaptcha-response": token,
        }),
      }).then(() => {
        this.$router.push({
          name: "result",
          query: {
            data: btoa(JSON.stringify(this.answerArray)),
          },
        });
      });
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  max-width: min(1000px, calc(100vw - 100px));
  min-height: calc(100vh - 220px);
  margin-inline: auto;
  transition: opacity 0.2s ease;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .container {
    max-width: calc(100vw - 15px);
  }
}

.page-number {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.current-page {
  width: 74px;
  height: 74px;
  background: #efbc1c;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: white;
  font-weight: 600;
}

.total-pages {
  font-size: 26px;
  color: #8c8c8c;
  font-weight: 300;
}

.pre-test-card {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  transition: opacity 0.2s ease;
  background-color: white;
}

.question {
  font-size: 28px;
  color: #262626;
  text-align: center;
}

.container :global(.v-selection-control .v-label) {
  opacity: 0.8 !important;
}

.answers {
  width: 100%;
}

.btn-disabled-opacity:disabled {
  opacity: 0.26 !important;
  filter: brightness(1.6);
}

.pre-test-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 100px;
  margin-top: 120px;
  max-width: 1000px;
  padding: 15px 20px;
  margin-inline: auto;
  border-radius: 10px;
  transition: opacity 0.2s ease;
  background-color: white;
  box-shadow: 0 16px 64px rgb(0 128 255 / 19%);
}

.navbar-subheader {
  font-size: 14px;
  color: #8c8c8c;
}

.spinner-container {
  height: 100vh;
}

.btn-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}

.pre-test-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 28px;
}

.result {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  line-height: 32px;
}

.result > span {
  text-wrap: nowrap;
}

.card-content {
  width: 100%;
  font-size: 18px;
}

@media screen and (width <= 600px) {
  .container {
    padding: 15px 10px;
  }

  .header {
    flex-direction: column;
    gap: 10px;
  }

  .header > span {
    text-align: center;
  }
}

header {
  position: fixed;
  height: 76px;
  transition: 0.2s all;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  width: 100vw;
  top: 0;
  left: 0;
}

.navigation-bar-content {
  max-width: min(80vw, 1453px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

nav {
  display: flex;
  justify-content: center;
  position: relative;
  gap: 16px;
}

.menu {
  display: none;
}

nav a {
  text-decoration: none;
  font-size: 16px;
  color: black;
  font-weight: normal;
  line-height: 1.5;
  transition: font-weight 0.05s ease;
  text-align: center;
}

nav a:nth-child(1) {
  width: 85px;
}

nav a:nth-child(2) {
  width: 154px;
}

nav a:nth-child(3) {
  width: 118px;
}

nav a:nth-child(4) {
  width: 59px;
}

nav a:hover {
  color: #0d4b72;
}

nav a:active {
  color: #1ca7fd;
  font-weight: 600;
}

nav a.router-link-active {
  color: #1ca7fd;
}

.profile-icon {
  border-radius: 50%;
}

@media only screen and (width <= 1280px) {
  nav {
    gap: 10px;
  }

  nav a {
    font-size: 12px;
  }

  nav a:nth-child(1) {
    width: 64px;
  }

  nav a:nth-child(2) {
    width: 115px;
  }

  nav a:nth-child(3) {
    width: 89px;
  }

  nav a:nth-child(4) {
    width: 46px;
  }

  .navigation-bar-content {
    max-width: 94vw;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (width <= 800px) {
  nav,
  .button-container {
    display: none;
  }

  .menu {
    display: initial;
  }

  .menu-item {
    text-decoration: none;
    font-size: 18px;
    color: black;
    font-weight: normal;
    line-height: 40px;
  }

  .btn-actions {
    flex-direction: column;
    gap: 10px;
  }
}
</style>
